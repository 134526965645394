<template>
  <dx-modal
    id="invoice-detail-modal"
    size="lg"
    title="Nota Fiscal de Serviço"
    :value="show"
    @input="close"
  >
    <div class="loading loading-lg" v-if="loading"></div>
    <template v-else-if="data">
      <div class="columns">
        <div class="column col-4 form-group">
          <label class="form-label text-small">Número da nota</label>
          <div class="text-bold">{{ data.number || '--' }}</div>
        </div>
        <div class="column col-5 form-group">
          <label class="form-label text-small">Código de verificação</label>
          <div>{{ data.checkCode || '--' }}</div>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label text-small">Situação</label>
          <div class="text-bold" :class="statusTextClass">
            {{ statusName }}
          </div>
        </div>
      </div>

      <div class="divider" />

      <div class="columns pt-2">
        <div class="column col-4 form-group">
          <label class="form-label text-small">Tomador de serviços</label>
          <div class="text-primary text-bold">{{ data.borrower.name }}</div>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label text-small">Documento</label>
          <div class="">{{ data.borrower.federalTaxNumber }}</div>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label text-small">Email</label>
          <div class="">{{ data.borrower.email }}</div>
        </div>
      </div>

      <div class="divider" />

      <div class="form-group pt-2 pb-2">
        <label class="form-label text-small">Código de serviço municipal</label>
        <div>{{ data.cityServiceCode }} {{ data.description }}</div>
      </div>

      <div class="divider" />

      <div class="columns pt-2">
        <div class="column col-3 form-group">
          <label class="form-label">Data de emissão</label>
          <dx-input-date
            :value="data.createdAt"
            :readonly="true"
          />
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Valor da nota fiscal</label>
          <div class="input-group">
            <span class="input-group-addon">R$</span>
            <dx-input-number
              :precision="2"
              :value="data.servicesAmount"
              :readonly="true"
            />
          </div>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Alíquota de ISS</label>
          <div class="input-group">
            <span class="input-group-addon">%</span>
            <dx-input-number
              :precision="2"
              :value="data.providerFiscalSetting.issRate"
              :readonly="true"
            />
          </div>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Deduções</label>
          <div class="input-group">
            <span class="input-group-addon">R$</span>
            <dx-input-number
              :precision="2"
              :value="data.deductionsAmount || 0"
              :readonly="true"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column col-6 form-group">
          <label class="form-label">Descrição da nota</label>
          <textarea
            class="form-input"
            rows="4"
            :value="data.description"
            :readonly="true"
          />
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Observações adicionais</label>
          <textarea
            class="form-input"
            rows="4"
            :value="data.additionalNotes"
            :readonly="true"
          />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button
        v-if="isPrintable"
        class="btn btn-info-outline btn-icon btn-icon-left mr-2"
        :class="{ loading: downloading }"
        @click="download"
        :disabled="downloading"
      ><fa-icon :icon="['fal', 'cloud-download']" /> Baixar Nota Fiscal</button>
      <button
        v-if="isPrintable"
        class="btn btn-info btn-icon btn-icon-left mr-2"
        :class="{ loading: printing }"
        @click="print"
        :disabled="printing"
      ><fa-icon :icon="['fal', 'print']" /> Imprimir Nota Fiscal</button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import {
  getName as getStatusName,
  getTextClass as getStatusTextClass,
} from './statuses';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      data: null,
      loading: false,
      printing: false,
      downloading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    statusName() {
      return this.data ? getStatusName(this.data.status) : '';
    },
    statusTextClass() {
      return this.data ? getStatusTextClass(this.data.status) : '';
    },
    isPrintable() {
      return this.data && ['ISSUED', 'CANCELED'].includes(this.data.status);
    },
    canPrint() {
      return true;
    },
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/invoices/${this.id}`);
        this.data = data;
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
    },
    async print() {
      if (!this.canPrint) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (!this.isPrintable || this.printing) return;

      this.printing = true;
      try {
        await this.$file.print(`/invoices/${this.data.id}/print`);
      } catch (e) {
        this.$toast.error(e);
      }
      this.printing = false;
    },
    async download() {
      if (!this.canPrint) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (!this.isPrintable || this.downloading) return;

      this.downloading = true;
      try {
        await this.$file.download(`/invoices/${this.data.id}/download`, {}, {
          method: 'POST',
          name: `NF-${this.data.number}.pdf`,
        });
      } catch (e) {
        this.$toast.error(e);
      }
      this.downloading = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <dx-modal
    id="invoice-create-modal"
    size="lg"
    title="Emitir Nota Fiscal de Serviço"
    :value="show"
    @input="close"
  >
    <div class="columns">
      <div
        class="column col-12 form-group"
        :class="{'has-error': $v.form.borrower.name.$error}"
      >
        <label class="form-label">Tomador de serviços</label>
        <dx-autocomplete
          id="patient-name"
          v-model="borrowerAc"
          :readonly="!!form.borrower.entityId"
          :source="findEntity"
          label="name"
          track-by="id"
          @select="setBorrower"
          :debounce="800"
          placeholder="Nome, CPF, CNPJ ou data de nascimento"
        >
          <template v-slot:action>
            <button
              class="btn btn-action input-group-btn btn-icon"
              @click="unsetBorrower"
              :class="borrowerAc ? 'btn-gray' : 'btn-neutral'"
              tabindex="-1"
            ><fa-icon :icon="['fal', borrowerAc ? 'times' : 'search']" />
            </button>
          </template>
          <template v-slot="{ item }">
            <div>{{ item.name }}</div>
            <small class="text-primary">{{ item.summary }}</small>
          </template>
        </dx-autocomplete>
        <template v-if="$v.form.borrower.name.$error">
          <div
            class="form-input-hint"
            v-if="!$v.form.borrower.name.required"
          >Campo obrigatório</div>
        </template>
      </div>
      <div
        class="column col-6 form-group"
        :class="{'has-error': $v.form.borrower.identity.value.$error}"
      >
        <label class="form-label" for="invoice-identity">Documento</label>
        <st-input-identity
          id="invoice-identity"
          :types="['cpf', 'cnpj']"
          v-model="form.borrower.identity"
        />
        <template v-if="$v.form.borrower.identity.value.$error">
          <div
            class="form-input-hint"
            v-if="!$v.form.borrower.identity.value.required"
          >Campo obrigatório</div>
          <div
            class="form-input-hint"
            v-else-if="$v.form.borrower.identity.value.cpf === false"
          >CPF inválido</div>
          <div
            class="form-input-hint"
            v-else-if="$v.form.borrower.identity.value.cnpj === false"
          >CNPJ inválido</div>
        </template>
      </div>
      <div
        class="column col-6 form-group"
        :class="{'has-error': $v.form.borrower.email.$error}"
      >
        <label class="form-label" for="invoice-email">Email</label>
        <input
          type="text"
          id="invoice-email"
          class="form-input"
          v-model="form.borrower.email"
          placeholder="email@email.com"
        >
        <template v-if="$v.form.borrower.email.$error">
          <div
            class="form-input-hint"
            v-if="!$v.form.borrower.email.required"
          >Campo obrigatório</div>
        </template>
      </div>
    </div>

    <div class="divider" />

    <div class="form-group pt-2 pb-2">
      <label class="form-label">Código de serviço municipal</label>
      <div class="mt-1">
        <strong class="text-info">{{ invoiceSettings.service.code }}</strong>
        <span class="text-small"> - {{ invoiceSettings.service.description }}</span>
      </div>
    </div>

    <div class="divider" />

    <div class="columns">
      <div
        class="column col-4 form-group"
        :class="{'has-error': $v.form.value.$error}"
      >
        <label class="form-label">Valor da nota fiscal</label>
        <div class="input-group">
          <span class="input-group-addon">R$</span>
          <dx-input-number
            class="form-input"
            maxlength="10"
            :precision="2"
            v-model="form.value"
          />
        </div>
        <template v-if="$v.form.value.$error">
          <div
            class="form-input-hint"
            v-if="!$v.form.value.required || !$v.form.value.minValue"
          >Campo obrigatório</div>
        </template>
      </div>
      <div class="column col-4 form-group">
        <label class="form-label">Alíquota de ISS</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.iss.percent"
            :precision="2"
          />
        </div>
      </div>
      <div class="column col-4 form-group"></div>
      <div class="column col-4 form-group">
        <label class="form-label">IR</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.ir.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.ir.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>
      <div class="column col-4 form-group">
        <label class="form-label">PIS</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.pis.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.pis.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>
      <div class="column col-4 form-group">
        <label class="form-label">COFINS</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.cofins.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.cofins.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>
      <div class="column col-4 form-group">
        <label class="form-label">CSLL</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.csll.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.csll.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>
      <div class="column col-4 form-group">
        <label class="form-label">INSS</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.inss.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.inss.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>

      <div class="column col-4 form-group">
        <label class="form-label">Outros</label>
        <div class="input-group">
          <span class="input-group-addon">%</span>
          <dx-input-number
            class="form-input"
            v-model="form.others.percent"
            :precision="2"
          />
          <span class="input-group-addon">R$</span>
          <input
            class="form-input text-right"
            :value="number(form.others.value)"
            :readonly="true"
            tabindex="-1"
          />
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column col-6 form-group">
        <label class="form-label">Descrição do serviço</label>
        <textarea
          class="form-input"
          rows="4"
          v-model="form.description"
        />
      </div>
      <div class="column col-6 form-group">
        <label class="form-label">Observações adicionais</label>
        <textarea
          class="form-input"
          rows="4"
          v-model="form.additionalNotes"
        />
      </div>
    </div>

    <template v-slot:footer>
      <button
        class="btn btn-primary mr-2"
        :class="{ loading: saving }"
        @click="save"
        :disabled="saving"
      >Emitir Nota Fiscal</button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { round } from 'lodash';
import { mapState } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import { CNPJ, CPF } from '@/data/identity-types';
import cpfValidator from '@/data/validators/cpf';
import cnpjValidator from '@/data/validators/cnpj';
import cpf from '@/filters/cpf';
import cnpj from '@/filters/cnpj';
import { clearNonNumbers } from '@/helpers/string';
import number from '@/filters/number';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    borrower: {
      type: Object,
    },
    value: {
      type: Number,
      default: 0,
    },
    iss: {
      type: Number,
      default: 0,
    },
    ir: {
      type: Number,
      default: 0,
    },
    pis: {
      type: Number,
      default: 0,
    },
    cofins: {
      type: Number,
      default: 0,
    },
    csll: {
      type: Number,
      default: 0,
    },
    inss: {
      type: Number,
      default: 0,
    },
    others: {
      type: Number,
      default: 0,
    },
    refId: {
      type: String,
    },
  },
  data() {
    return {
      saving: false,
      form: this.blankForm(),
      borrowerAc: null,
      identityTypes: [CPF, CNPJ],
      number,
    };
  },
  validations() {
    const identityValidator = this.form.borrower.identity.type === CPF
      ? { cpf: cpfValidator }
      : { cnpj: cnpjValidator };

    return {
      form: {
        borrower: {
          name: { required },
          email: { required },
          identity: {
            value: {
              required,
              ...identityValidator,
            },
          },
        },
        value: {
          required,
          minValue: minValue(0.01),
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      branch: ({ auth }) => auth.user.branch,
    }),
    invoiceSettings() {
      return this.branch.settings.invoice;
    },
  },
  methods: {
    init() {
      if (this.borrower) {
        if (this.borrower.id) {
          this.form.borrower.id = this.borrower.id;
        }
        if (this.borrower.name) {
          this.form.borrower.name = this.borrower.name;
          this.borrowerAc = {
            id: this.borrower.id || null,
            name: this.borrower.name,
          };
        }
        if (this.borrower.email) {
          this.form.borrower.email = this.borrower.email;
        }
        if (this.borrower.identity && this.borrower.identity.value) {
          this.form.borrower.identity = this.borrower.identity;
        }
      }

      this.form.value = this.value;

      const percentFields = [
        'iss',
        'ir',
        'pis',
        'cofins',
        'csll',
        'inss',
        'others',
      ];

      percentFields.forEach((field) => {
        this.form[field].percent = this[field];
        this.$watch(
          `form.${field}.percent`,
          () => {
            const value = this.form.value * this.form[field].percent / 100;
            this.form[field].value = round(value, 2);
          },
          { immediate: true },
        );
      });

      if (this.invoiceSettings) {
        if (!this.form.iss.percent) {
          this.form.iss.percent = this.invoiceSettings.issRate;
        }
        this.form.serviceCode = this.invoiceSettings.service.code;
        this.form.description = this.invoiceSettings.service.description;
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;
      try {
        await this.$http.post('/invoices', this.prepareFormData());
        this.$toast.success('Nota fiscal enviada para emissão');
        this.close();
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    prepareFormData() {
      return {
        borrower: {
          entityId: this.form.borrower.id,
          name: this.form.borrower.name,
          federalTaxNumber: clearNonNumbers(this.form.borrower.identity.value),
          email: this.form.borrower.email,
          // address: {
          //   country: 'BRA',
          //   postalCode: '',
          //   street: '',
          //   number: '',
          //   additionalInformation: '',
          //   district: '',
          //   city: { code: '', name: '' },
          //   state: '',
          // },
        },
        cityServiceCode: this.form.serviceCode,
        description: this.form.description,
        servicesAmount: this.form.value,
        issRate: this.form.iss.percent / 100,
        issTaxAmount: this.form.iss.value,
        deductionsAmount: 0,
        irAmountWithheld: this.form.ir.value,
        pisAmountWithheld: this.form.pis.value,
        cofinsAmountWithheld: this.form.cofins.value,
        csllAmountWithheld: this.form.csll.value,
        inssAmountWithheld: this.form.inss.value,
        othersAmountWithheld: this.form.others.value,
        additionalInformation: this.form.additionalNotes,
        refId: this.refId,
      };
    },
    async findEntity(search) {
      const params = {
        customerId: this.branch.customerId,
        search: /^\d/.test(search) ? search : `^${search}`,
      };

      try {
        const { data } = await this.$http.get('/entities', { params });
        if (data.items.length > 0) {
          return data.items.map((item) => {
            const summaryParts = [item.type === 'person' ? 'PF' : 'PJ'];
            if (item.identity && item.identity.value) {
              summaryParts.push((
                item.type === 'person' ? cpf(item.identity.value) : cnpj(item.identity.value)
              ));
            }
            if (item.email) {
              summaryParts.push(item.email);
            }

            item.summary = summaryParts.join(' - ');
            return item;
          });
        }
      } catch (e) {
        this.$toast.error(e);
      }

      return [{
        id: null,
        name: search,
        summary: 'Não cadastrado',
      }];
    },
    setBorrower(data) {
      if (data) {
        this.form.borrower.id = data.id || null;
        this.form.borrower.name = data.name;
        this.form.borrower.email = data.email || '';
        if (data.identity && this.identityTypes.includes(data.identity.type)) {
          this.form.borrower.identity = data.identity;
        } else {
          this.form.borrower.identity = this.blankForm().borrower.identity;
        }
      } else {
        this.form = this.blankForm();
      }
    },
    unsetBorrower() {
      this.setBorrower(null);
      this.borrowerAc = null;
    },
    close() {
      this.$emit('close');
    },
    blankForm() {
      return {
        borrower: {
          id: null,
          name: '',
          identity: {
            type: CPF,
            value: '',
          },
          email: '',
        },
        serviceCode: '',
        description: '',
        additionalNotes: '',
        value: 0,
        iss: { percent: 0, value: 0 },
        ir: { percent: 0, value: 0 },
        pis: { percent: 0, value: 0 },
        cofins: { percent: 0, value: 0 },
        csll: { percent: 0, value: 0 },
        inss: { percent: 0, value: 0 },
        others: { percent: 0, value: 0 },
      };
    },
  },
};
</script>
